import React, { useEffect, useState } from 'react';
import { X, ChevronLeft, ChevronRight, ArrowLeft, ArrowRight } from 'react-feather';
import { useSidePanel } from '../context/SidePanelContext';
import styles from '../styles/sidePanel.module.css';

const SidePanel = () => {
  const { file, isCollapsed, position, closePanel, toggleCollapse, togglePosition } = useSidePanel();
  const [key, setKey] = useState(0);
  const [objectUrl, setObjectUrl] = useState('');

  useEffect(() => {
    // Add classes to body to control main content layout
    document.body.classList.toggle('panel-open', !isCollapsed && file);
    document.body.classList.toggle('panel-right', position === 'right');
    document.body.classList.toggle('panel-left', position === 'left');

    return () => {
      // Clean up classes when component unmounts
      document.body.classList.remove('panel-open', 'panel-right', 'panel-left');
    };
  }, [isCollapsed, position, file]);

  useEffect(() => {
    if (file) {
      const url = URL.createObjectURL(file);
      setObjectUrl(url);
      return () => URL.revokeObjectURL(url);
    }
  }, [file]);

  useEffect(() => {
    setKey(prev => prev + 1);
  }, [position]);

  const getCollapseIcon = () => {
    if (position === 'right') {
      return isCollapsed ? <ChevronLeft size={24} /> : <ChevronRight size={24} />;
    } else {
      return isCollapsed ? <ChevronRight size={24} /> : <ChevronLeft size={24} />;
    }
  };

  if (!file) return null;

  return (
    <div className={`${styles.sidePanel} ${styles[position]} ${isCollapsed ? styles.collapsed : ''}`}>
      <div className={styles.header}>
        {position === 'right' ? (
          <>
            <div className={styles.headerControls}>
              <button 
                className={styles.positionButton} 
                onClick={togglePosition}
                title="Move to left side"
              >
                <ArrowLeft size={24} />
              </button>
            </div>
            <h3>{file?.name}</h3>
            <button className={styles.closeButton} onClick={closePanel} title="Close panel">
              <X size={24} />
            </button>
          </>
        ) : (
          <>
            <button className={styles.closeButton} onClick={closePanel} title="Close panel">
              <X size={24} />
            </button>
            <h3>{file?.name}</h3>
            <button 
              className={styles.positionButton} 
              onClick={togglePosition}
              title="Move to right side"
            >
              <ArrowRight size={24} />
            </button>
          </>
        )}
      </div>

      <button 
        className={`${styles.collapseButton} ${isCollapsed ? styles.collapsed : ''}`}
        onClick={toggleCollapse}
        title={isCollapsed ? 'Expand panel' : 'Collapse panel'}
      >
        <div className={styles.collapseIcon}>
          {getCollapseIcon()}
        </div>
      </button>

      <div className={styles.content}>
        <iframe
          key={key}
          src={objectUrl}
          className={styles.viewer}
          title="Document viewer"
        />
      </div>
    </div>
  );
};

export default SidePanel;
