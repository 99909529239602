const STORAGE_KEY = 'quizmate_files';

export const saveFileToStorage = async (file) => {
  try {
    // Convert file to base64
    const base64 = await fileToBase64(file);
    
    // Get existing files
    const existingFiles = getFilesFromStorage();
    
    // Create file object with metadata
    const fileObject = {
      id: generateId(),
      name: file.name,
      type: file.type,
      size: file.size,
      content: base64,
      createdAt: new Date().toISOString()
    };
    
    // Add new file to array
    existingFiles.push(fileObject);
    
    // Save back to storage
    localStorage.setItem(STORAGE_KEY, JSON.stringify(existingFiles));
    
    return fileObject;
  } catch (error) {
    console.error('Error saving file:', error);
    throw new Error('Failed to save file');
  }
};

export const getFilesFromStorage = () => {
  try {
    const files = localStorage.getItem(STORAGE_KEY);
    return files ? JSON.parse(files) : [];
  } catch (error) {
    console.error('Error getting files:', error);
    return [];
  }
};

export const deleteFileFromStorage = (fileId) => {
  try {
    const files = getFilesFromStorage();
    const updatedFiles = files.filter(file => file.id !== fileId);
    localStorage.setItem(STORAGE_KEY, JSON.stringify(updatedFiles));
  } catch (error) {
    console.error('Error deleting file:', error);
    throw new Error('Failed to delete file');
  }
};

export const base64ToFile = (base64String, filename, type) => {
  const arr = base64String.split(',');
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  
  return new File([u8arr], filename, { type: type || mime });
};

const fileToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

const generateId = () => {
  return Math.random().toString(36).substr(2, 9) + '_' + Date.now();
};

export const formatFileSize = (bytes) => {
  if (bytes === 0) return '0 Bytes';
  const k = 1024;
  const sizes = ['Bytes', 'KB', 'MB', 'GB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
};
