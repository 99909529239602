import React from 'react';
import { createPortal } from 'react-dom';
import { FiX, FiLock, FiBook, FiClipboard, FiFileText } from 'react-icons/fi';
import styles from '../styles/modal.module.css';

const Modal = ({ 
  isOpen, 
  onClose, 
  title, 
  children, 
  isPremiumFeature = false,
  isUserPremium = false,
  onOptionSelect 
}) => {
  // Close on escape key
  React.useEffect(() => {
    if (!isOpen) return;

    const handleEscape = (e) => {
      if (e.key === 'Escape') onClose();
    };
    
    window.addEventListener('keydown', handleEscape);
    document.body.style.overflow = 'hidden';
    
    return () => {
      window.removeEventListener('keydown', handleEscape);
      document.body.style.overflow = '';
    };
  }, [isOpen, onClose]);

  if (!isOpen) return null;

  const options = [
    {
      id: 'quiz',
      title: 'Quiz Mode',
      description: 'Test your knowledge with interactive questions',
      icon: <FiClipboard size={20} />
    },
    {
      id: 'flashcards',
      title: 'Flashcards',
      description: 'Study with digital flashcards',
      icon: <FiBook size={20} />
    },
    {
      id: 'summary',
      title: 'Summary',
      description: 'Get a concise overview of key points',
      icon: <FiFileText size={20} />
    }
  ];

  const modalContent = (
    <div className={styles.backdrop} onClick={onClose}>
      <div 
        className={styles.modal} 
        onClick={e => e.stopPropagation()}
      >
        <header className={styles.header}>
          <h2 className={styles.title}>
            {title}
            {isPremiumFeature && !isUserPremium && (
              <span className={styles.premium}>
                <FiLock /> Premium
              </span>
            )}
          </h2>
          <button 
            className={styles.close} 
            onClick={onClose}
            aria-label="Close"
          >
            <FiX />
          </button>
        </header>

        <div className={styles.content}>
          {isPremiumFeature && !isUserPremium ? (
            <div className={styles.premiumContent}>
              <FiLock size={48} />
              <h3>Premium Feature</h3>
              <p>Upgrade your account to access this feature</p>
              <button className={styles.upgrade}>
                Upgrade to Premium
              </button>
            </div>
          ) : children ? (
            children
          ) : (
            <div className={styles.optionsGrid}>
              {options.map(option => (
                <button
                  key={option.id}
                  className={styles.optionCard}
                  onClick={() => onOptionSelect?.(option.id)}
                >
                  <div className={styles.optionIcon}>
                    {option.icon}
                  </div>
                  <h3 className={styles.optionTitle}>
                    {option.title}
                  </h3>
                  <p className={styles.optionDescription}>
                    {option.description}
                  </p>
                </button>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );

  return createPortal(
    modalContent,
    document.getElementById('modal-root') || document.body
  );
};

export default React.memo(Modal);
