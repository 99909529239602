import React, { useState } from 'react';
import { FiUpload, FiSettings, FiList, FiCheckSquare, FiSliders, FiCheck, FiFile, FiFileText } from 'react-icons/fi';
import styles from '../styles/quizGenerator.module.css';

const QuizGenerator = ({ onGenerate }) => {
  const [activeTab, setActiveTab] = useState('input');
  const [completedTabs, setCompletedTabs] = useState([]);
  const [inputContent, setInputContent] = useState('');
  const [uploadedFile, setUploadedFile] = useState(null);
  const [settings, setSettings] = useState({
    questionTypes: {
      multipleChoice: true,
      trueFalse: true,
      shortAnswer: false,
      essay: false
    },
    difficulty: 'medium',
    numQuestions: 10,
    timeLimit: 0
  });

  const tabs = [
    { id: 'input', label: 'Content Input', icon: FiUpload },
    { id: 'settings', label: 'Quiz Settings', icon: FiSettings },
    { id: 'preview', label: 'Preview', icon: FiList }
  ];

  const handleSettingsChange = (key, value) => {
    if (key.includes('.')) {
      const [parent, child] = key.split('.');
      setSettings(prev => ({
        ...prev,
        [parent]: {
          ...prev[parent],
          [child]: value
        }
      }));
    } else {
      setSettings(prev => ({
        ...prev,
        [key]: value
      }));
    }
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setUploadedFile(file);
      // You can also read the file content here if needed
      const reader = new FileReader();
      reader.onload = (e) => {
        setInputContent(e.target.result);
      };
      reader.readAsText(file);
    }
  };

  const isTabCompleted = (tabId) => completedTabs.includes(tabId);
  const isTabDisabled = (tabId) => {
    const currentIndex = tabs.findIndex(tab => tab.id === activeTab);
    const tabIndex = tabs.findIndex(tab => tab.id === tabId);
    return tabIndex > currentIndex + 1;
  };

  const handleTabClick = (tabId) => {
    if (isTabDisabled(tabId)) return;
    setActiveTab(tabId);
  };

  const handleNext = () => {
    const currentIndex = tabs.findIndex(tab => tab.id === activeTab);
    if (currentIndex < tabs.length - 1) {
      setCompletedTabs(prev => [...new Set([...prev, activeTab])]);
      setActiveTab(tabs[currentIndex + 1].id);
    } else if (currentIndex === tabs.length - 1) {
      onGenerate?.({ ...settings, content: inputContent, file: uploadedFile });
    }
  };

  const handleBack = () => {
    const currentIndex = tabs.findIndex(tab => tab.id === activeTab);
    if (currentIndex > 0) {
      setActiveTab(tabs[currentIndex - 1].id);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.tabs}>
        {tabs.map((tab) => (
          <button
            key={tab.id}
            className={`${styles.tab} 
              ${activeTab === tab.id ? styles.active : ''} 
              ${isTabCompleted(tab.id) ? styles.completed : ''}
              ${isTabDisabled(tab.id) ? styles.disabled : ''}`}
            onClick={() => handleTabClick(tab.id)}
            disabled={isTabDisabled(tab.id)}
          >
            {isTabCompleted(tab.id) ? <FiCheck /> : <tab.icon />}
            <span>{tab.label}</span>
          </button>
        ))}
      </div>

      <div className={styles.content}>
        {activeTab === 'input' && (
          <div className={styles.inputSection}>
            <div className={styles.textInput}>
              <textarea
                className={styles.textarea}
                placeholder="Enter or paste your study content here..."
                value={inputContent}
                onChange={(e) => setInputContent(e.target.value)}
                rows={8}
              />
            </div>
            
            <div className={styles.fileUpload}>
              <input
                type="file"
                id="quiz-file-upload"
                className={styles.fileInput}
                accept=".pdf,.docx,.txt,.md"
                onChange={handleFileUpload}
              />
              <label htmlFor="quiz-file-upload" className={styles.fileLabel}>
                <FiUpload size={24} />
                <span>Drop files or click to upload</span>
                <small>Supports PDF, DOCX, TXT, MD</small>
              </label>
            </div>
          </div>
        )}

        {activeTab === 'settings' && (
          <div className={styles.settingsSection}>
            <div className={styles.settingGroup}>
              <h3><FiCheckSquare /> Question Types</h3>
              <div className={styles.checkboxGrid}>
                <label>
                  <input
                    type="checkbox"
                    checked={settings.questionTypes.multipleChoice}
                    onChange={(e) => handleSettingsChange('questionTypes.multipleChoice', e.target.checked)}
                  />
                  Multiple Choice
                </label>
                <label>
                  <input
                    type="checkbox"
                    checked={settings.questionTypes.trueFalse}
                    onChange={(e) => handleSettingsChange('questionTypes.trueFalse', e.target.checked)}
                  />
                  True/False
                </label>
                <label>
                  <input
                    type="checkbox"
                    checked={settings.questionTypes.shortAnswer}
                    onChange={(e) => handleSettingsChange('questionTypes.shortAnswer', e.target.checked)}
                  />
                  Short Answer
                </label>
                <label>
                  <input
                    type="checkbox"
                    checked={settings.questionTypes.essay}
                    onChange={(e) => handleSettingsChange('questionTypes.essay', e.target.checked)}
                  />
                  Essay Questions
                </label>
              </div>
            </div>

            <div className={styles.settingGroup}>
              <h3><FiSliders /> Quiz Configuration</h3>
              <div className={styles.settingControls}>
                <div className={styles.settingControl}>
                  <label>Difficulty Level</label>
                  <select
                    value={settings.difficulty}
                    onChange={(e) => handleSettingsChange('difficulty', e.target.value)}
                  >
                    <option value="easy">Easy</option>
                    <option value="medium">Medium</option>
                    <option value="hard">Hard</option>
                  </select>
                </div>

                <div className={styles.settingControl}>
                  <label>Number of Questions</label>
                  <input
                    type="number"
                    min="1"
                    max="50"
                    value={settings.numQuestions}
                    onChange={(e) => handleSettingsChange('numQuestions', parseInt(e.target.value))}
                  />
                </div>

                <div className={styles.settingControl}>
                  <label>Time Limit (minutes)</label>
                  <input
                    type="number"
                    min="0"
                    value={settings.timeLimit}
                    onChange={(e) => handleSettingsChange('timeLimit', parseInt(e.target.value))}
                  />
                </div>
              </div>
            </div>
          </div>
        )}

        {activeTab === 'preview' && (
          <div className={styles.previewSection}>
            <div className={styles.previewContent}>
              <h3>Quiz Settings Preview</h3>
              <div className={styles.previewGrid}>
                <div className={styles.previewItem}>
                  <strong>Question Types</strong>
                  <ul>
                    {Object.entries(settings.questionTypes)
                      .filter(([, enabled]) => enabled)
                      .map(([type]) => (
                        <li key={type}>{type.replace(/([A-Z])/g, ' $1').trim()}</li>
                      ))}
                  </ul>
                </div>
                <div className={styles.previewItem}>
                  <strong>Configuration</strong>
                  <ul>
                    <li>Difficulty: {settings.difficulty}</li>
                    <li>Questions: {settings.numQuestions}</li>
                    <li>Time Limit: {settings.timeLimit > 0 ? `${settings.timeLimit} minutes` : 'No limit'}</li>
                  </ul>
                </div>
                <div className={`${styles.previewItem} ${styles.contentPreview}`}>
                  <strong>
                    <FiFileText /> Source Content
                  </strong>
                  {uploadedFile ? (
                    <div className={styles.uploadedFile}>
                      <FiFile /> {uploadedFile.name}
                    </div>
                  ) : null}
                  <div className={styles.contentText}>
                    {inputContent || 'No content provided yet'}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      <div className={styles.actions}>
        <button 
          className={`${styles.button} ${styles.secondary}`}
          onClick={handleBack}
          disabled={activeTab === 'input'}
        >
          Back
        </button>
        <button 
          className={`${styles.button} ${styles.primary}`}
          onClick={handleNext}
        >
          {activeTab === 'preview' ? 'Generate Quiz' : 'Next'}
        </button>
      </div>
    </div>
  );
};

export default QuizGenerator;
