import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { ArrowLeft, Mail, Lock, User } from "react-feather";
import styles from "../styles/auth.module.css";

const AuthPage = () => {
  const [isSignUp, setIsSignUp] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [formKey, setFormKey] = useState(0);

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form submitted:", { email, password, name });
  };

  const handleGoogleLogin = () => {
    console.log("Logging in with Google");
  };

  const toggleForm = () => {
    setFormKey((prev) => prev + 1);
    setIsSignUp(!isSignUp);
    setEmail("");
    setPassword("");
    setName("");
  };

  return (
    <div className={styles.authContainer}>
      <div className={styles.content}>
        <Link to="/" className={styles.backButton}>
          <ArrowLeft size={18} />
          Back
        </Link>

        <div className={styles.formContainer}>
          <div className={styles.titleWrapper}>
            <h1 className={styles.title}>
              {isSignUp ? "Create Account" : "Welcome Back"}
            </h1>
            <p className={styles.subtitle}>
              {isSignUp
                ? "Join our community of learners and start your journey"
                : "Sign in to continue your learning journey"}
            </p>
          </div>

          <div className={styles.socialButtons}>
            <button
              type="button"
              onClick={handleGoogleLogin}
              className={`${styles.socialButton} ${styles.googleButton}`}
            >
              <svg
                viewBox="0 0 24 24"
                width="24"
                height="24"
                className={styles.googleIcon}
              >
                <path
                  fill="currentColor"
                  d="M12.48 10.92v3.28h7.84c-.24 1.84-.853 3.187-1.787 4.133-1.147 1.147-2.933 2.4-6.053 2.4-4.827 0-8.6-3.893-8.6-8.72s3.773-8.72 8.6-8.72c2.6 0 4.507 1.027 5.907 2.347l2.307-2.307C18.747 1.44 16.133 0 12.48 0 5.867 0 .307 5.387.307 12s5.56 12 12.173 12c3.573 0 6.267-1.173 8.373-3.36 2.16-2.16 2.84-5.213 2.84-7.667 0-.76-.053-1.467-.173-2.053H12.48z"
                />
              </svg>
              <span>Continue with Google</span>
            </button>
          </div>

          <div className={styles.divider}>
            <span>or continue with email</span>
          </div>

          <form key={formKey} onSubmit={handleSubmit} className={styles.form}>
            <div className={styles.formFields}>
              {isSignUp && (
                <div className={styles.inputGroup}>
                  <label htmlFor="name" className={styles.label}>
                    Full Name
                  </label>
                  <div className={styles.inputWrapper}>
                    <User size={16} className={styles.inputIcon} />
                    <input
                      type="text"
                      id="name"
                      className={styles.input}
                      placeholder="Your Name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      required
                    />
                  </div>
                </div>
              )}

              <div className={styles.inputGroup}>
                <label htmlFor="email" className={styles.label}>
                  Email Address
                </label>
                <div className={styles.inputWrapper}>
                  <Mail size={16} className={styles.inputIcon} />
                  <input
                    type="email"
                    id="email"
                    className={styles.input}
                    placeholder="you@example.com"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </div>
              </div>

              <div className={styles.inputGroup}>
                <label htmlFor="password" className={styles.label}>
                  Password
                </label>
                <div className={styles.inputWrapper}>
                  <Lock size={16} className={styles.inputIcon} />
                  <input
                    type="password"
                    id="password"
                    className={styles.input}
                    placeholder="••••••••"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                </div>
              </div>

              {!isSignUp && (
                <div className={styles.forgotPassword}>
                  <Link to="/forgot-password" className={styles.link}>
                    Forgot your password?
                  </Link>
                </div>
              )}
            </div>

            <button type="submit" className={styles.submitButton}>
              {isSignUp ? "Create Account" : "Sign In"}
            </button>

            <div className={styles.switchForm}>
              <p>
                <span>
                  {isSignUp
                    ? "Already have an account?"
                    : "Don't have an account?"}
                </span>
                <button
                  type="button"
                  onClick={toggleForm}
                  className={styles.switchButton}
                >
                  {isSignUp ? "Sign In" : "Create one for free"}
                </button>
              </p>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AuthPage;
