import React, { useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import styles from '../styles/pricing.module.css';
import { ArrowLeft, Check, Star, Users, Zap, Shield, Database, HelpCircle } from 'react-feather';

const PRICING_PLANS = [
  {
    name: 'Basic',
    price: '$5',
    period: 'per month',
    description: 'Perfect for individual learners getting started with QuizMate.',
    features: [
      '25 AI-powered quizzes monthly',
      'Smart flashcard generation',
      'Text-to-quiz conversion',
      'Basic study materials library',
      'Email support within 24 hours'
    ],
    highlighted: false,
    action: 'Start Basic Plan',
    link: '/signup'
  },
  {
    name: 'Annual Pro',
    price: '$99',
    period: 'per year',
    description: 'Our most popular plan. Save 31% compared to monthly.',
    features: [
      'All Pro features included',
      'Save 31% compared to monthly',
      'Unlimited AI-powered quizzes',
      'Advanced study analytics',
      'Personalized learning paths',
      'Priority 24/7 support access',
      'Early access to new features'
    ],
    highlighted: true,
    action: 'Save with Annual',
    link: '/signup/pro'
  },
  {
    name: 'Pro',
    price: '$12',
    period: 'per month',
    description: 'Enhanced features for active learners.',
    features: [
      'Unlimited AI-powered quizzes',
      'Advanced study materials',
      'Audio & image quiz creation',
      'Performance analytics dashboard',
      'Cross-topic knowledge mapping',
      'Priority email support',
      'Spaced repetition learning'
    ],
    highlighted: false,
    action: 'Upgrade to Pro',
    link: '/signup/pro'
  }
];

const FEATURES = [
  {
    icon: <Zap size={24} />,
    title: 'AI-Powered Learning',
    description: 'Advanced algorithms adapt to your learning style for maximum efficiency.'
  },
  {
    icon: <Users size={24} />,
    title: 'Collaborative Tools',
    description: 'Work together with classmates and teachers in real-time.'
  },
  {
    icon: <Shield size={24} />,
    title: 'Secure Platform',
    description: 'Enterprise-grade security to protect your data and privacy.'
  },
  {
    icon: <Database size={24} />,
    title: 'Comprehensive Analytics',
    description: 'Track progress and identify areas for improvement with detailed insights.'
  }
];

const FAQ = [
  {
    question: 'What happens after I subscribe?',
    answer: 'You\'ll get immediate access to all features included in your plan. Start creating AI-powered quizzes right away.'
  },
  {
    question: 'Can I switch plans later?',
    answer: 'Yes, you can upgrade or downgrade your plan at any time. Changes take effect on your next billing cycle.'
  },
  {
    question: 'What payment methods do you accept?',
    answer: 'We accept all major credit cards and PayPal for your convenience.'
  },
  {
    question: 'What\'s the difference between Basic and Pro?',
    answer: 'Pro plans offer unlimited quizzes, advanced analytics, and priority support. Basic is great for getting started, while Pro is perfect for serious learners.'
  }
];

const Pricing = () => {
  const handleIntersection = useCallback((entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        entry.target.classList.add(styles.inView);
        entry.target.observer?.unobserve(entry.target);
      }
    });
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(handleIntersection, {
      threshold: 0.1,
      rootMargin: '50px',
    });

    const elements = document.querySelectorAll('[data-animate]');
    elements.forEach((el) => observer.observe(el));

    return () => observer.disconnect();
  }, [handleIntersection]);

  return (
    <div className={styles.pricingContainer}>
      <div className={styles.content}>
        <Link to="/" className={styles.backButton}>
          <ArrowLeft size={18} />
          Back
        </Link>

        <header className={styles.header}>
          <div className={styles.titleWrapper} data-animate>
            <h1 className={styles.title}>
              Simple, Transparent Pricing
            </h1>
          </div>
          <p className={styles.subtitle} data-animate>
            Choose the perfect plan for your learning journey.
            All plans include core features and updates.
          </p>
        </header>

        <section className={styles.section}>
          <div className={styles.plansGrid}>
            {PRICING_PLANS.map((plan, index) => (
              <div
                key={plan.name}
                className={`${styles.planCard} ${plan.highlighted ? styles.highlighted : ''}`}
                data-animate
                style={{ animationDelay: `${0.1 * (index + 2)}s` }}
              >
                <div className={styles.planHeader}>
                  <h3 className={styles.planName}>{plan.name}</h3>
                  <div className={styles.planPrice}>
                    <span className={styles.price}>{plan.price}</span>
                    <span className={styles.period}>{plan.period}</span>
                  </div>
                  <p className={styles.planDescription}>{plan.description}</p>
                </div>
                <ul className={styles.planFeatures}>
                  {plan.features.map((feature) => (
                    <li key={feature}>
                      <Check size={16} />
                      {feature}
                    </li>
                  ))}
                </ul>
                <Link to={plan.link} className={styles.planAction}>
                  {plan.action}
                </Link>
              </div>
            ))}
          </div>
        </section>

        <section className={styles.section}>
          <h2 className={styles.sectionTitle} data-animate>
            Key Features
          </h2>
          <div className={styles.featuresGrid}>
            {FEATURES.map((feature, index) => (
              <div
                key={feature.title}
                className={styles.featureCard}
                data-animate
                style={{ animationDelay: `${0.1 * (index + 2)}s` }}
              >
                <div className={styles.featureIcon}>{feature.icon}</div>
                <h3 className={styles.featureTitle}>{feature.title}</h3>
                <p className={styles.featureDescription}>{feature.description}</p>
              </div>
            ))}
          </div>
        </section>

        <section className={styles.section}>
          <h2 className={styles.sectionTitle} data-animate>
            Frequently Asked Questions
          </h2>
          <div className={styles.faqGrid}>
            {FAQ.map((item, index) => (
              <div
                key={item.question}
                className={styles.faqCard}
                data-animate
                style={{ animationDelay: `${0.1 * (index + 2)}s` }}
              >
                <div className={styles.faqIcon}>
                  <HelpCircle size={20} />
                </div>
                <h3 className={styles.faqQuestion}>{item.question}</h3>
                <p className={styles.faqAnswer}>{item.answer}</p>
              </div>
            ))}
          </div>
        </section>
      </div>
    </div>
  );
};

export default Pricing;
