import React, { createContext, useContext, useState } from 'react';

const SidePanelContext = createContext();

export const SidePanelProvider = ({ children }) => {
  const [panelFile, setPanelFile] = useState(null);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [position, setPosition] = useState('right');

  const openPanel = (file) => {
    setPanelFile(file);
    setIsCollapsed(false);
  };

  const closePanel = () => {
    setPanelFile(null);
  };

  const toggleCollapse = () => {
    setIsCollapsed(prev => !prev);
  };

  const togglePosition = () => {
    setPosition(prev => prev === 'right' ? 'left' : 'right');
  };

  return (
    <SidePanelContext.Provider 
      value={{
        file: panelFile,
        isCollapsed,
        position,
        openPanel,
        closePanel,
        toggleCollapse,
        togglePosition
      }}
    >
      {children}
    </SidePanelContext.Provider>
  );
};

export const useSidePanel = () => {
  const context = useContext(SidePanelContext);
  if (!context) {
    throw new Error('useSidePanel must be used within a SidePanelProvider');
  }
  return context;
};
