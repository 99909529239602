import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import landingCss from "../styles/landing.module.css";
import Lottie from "lottie-react";
import globe from "../assets/img/globe-empty.json";
import {
  FaGraduationCap,
  FaBrain,
  FaRobot,
  FaFlask,
  FaChartLine,
  FaMagic,
  FaArrowRight,
  FaPlay,
  FaChevronRight,
} from "react-icons/fa";
import { FaMicrophone, FaPencilAlt, FaFileAlt } from "react-icons/fa";
import { motion, useScroll, useTransform } from "framer-motion";
import ModernDemo from "../components/ModernDemo";

const stats = [
  {
    value: "10K+",
    label: "Students Impacted",
    description: "Empowering learners worldwide to achieve academic success",
  },
  {
    value: "100K+",
    label: "AI Questions Generated",
    description: "Personalized questions for smarter and faster learning",
  },
  {
    value: "300K+",
    label: "Hours Saved",
    description: "Helping students study efficiently and save time",
  },
];

const features = [
  {
    icon: <FaBrain />,
    title: "AI Study Assistant",
    description:
      "Get instant answers and personalized study recommendations powered by advanced AI",
  },
  {
    icon: <FaMicrophone />,
    title: "Voice to Notes",
    description:
      "Convert lectures and voice notes into structured study materials automatically",
  },
  {
    icon: <FaPencilAlt />,
    title: "Smart OCR",
    description:
      "Transform handwritten notes into digital format with our advanced recognition system",
  },
];

const capabilities = [
  {
    icon: <FaFileAlt />,
    title: "Multi-Format Support",
    description:
      "Import PDFs, Word docs, images, and audio files - we handle it all",
    features: [
      "Automatic content extraction",
      "Smart formatting",
      "Instant conversion",
    ],
  },
  {
    icon: <FaChartLine />,
    title: "Progress Analytics",
    description:
      "Track your learning journey with detailed insights and recommendations",
    features: [
      "Performance metrics",
      "Learning patterns",
      "Improvement suggestions",
    ],
  },
  {
    icon: <FaMagic />,
    title: "Smart Generation",
    description: "Create study materials instantly with our AI-powered tools",
    features: ["Flashcard generation", "Quiz creation", "Summary notes"],
  },
];

const testimonials = [
  {
    quote:
      "QuizBud's AI assistant helps me create effective study materials in minutes. The flashcards are perfect for quick revision!",
    author: "Alex Chen",
    title: "Computer Science Student",
    initial: "A",
  },
  {
    quote:
      "The smart quiz generation saves me hours of prep time. It's like having a personal tutor that knows exactly what I need to review.",
    author: "Maya Patel",
    title: "Biology Major",
    initial: "M",
  },
  {
    quote:
      "Being able to convert my lecture recordings into organized notes is incredible. This app has transformed my study routine.",
    author: "David Kim",
    title: "Business Student",
    initial: "D",
  },
];

const faq = [
  {
    question: "How does QuizBud generate questions?",
    answer:
      "Our AI analyzes your study materials to create relevant, challenging questions that are tailored to your learning goals.",
  },
  {
    question: "Can I upgrade or downgrade my plan?",
    answer:
      "Yes! You can change your plan anytime. Upgrades are instant, and downgrades take effect at the end of your billing period.",
  },
  {
    question: "Is there a limit to study materials?",
    answer:
      "Basic plans include 25 uploads monthly. Pro plans offer unlimited uploads with support for text, audio, and images.",
  },
  {
    question: "How accurate are the AI-generated quizzes?",
    answer:
      "Our AI maintains high accuracy through advanced NLP, with continuous improvements and user feedback integration.",
  },
  {
    question: "What payment methods do you accept?",
    answer:
      "We accept all major credit cards, PayPal, and Apple Pay, with secure processing through Stripe.",
  },
  {
    question: "Is there a free trial?",
    answer: "Yes! Try QuizBud free for 7 days with full Pro features. No credit card required.",
  },
];

const Arrow = () => (
  <span className={landingCss.arrowIcon}>
    <span className={landingCss.arrowLine} />
    <span className={landingCss.arrowPoint} />
  </span>
);

function LandingPage() {
  const [mounted, setMounted] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const heroRef = useRef(null);
  const contentRef = useRef(null);
  const animationRef = useRef(null);
  const featuresRef = useRef(null);
  const demoSectionRef = useRef(null);

  const scrollToFeatures = () => {
    featuresRef.current?.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  const scrollToDemo = () => {
    demoSectionRef.current?.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };

  useEffect(() => {
    const handleParallax = (e) => {
      if (!heroRef.current || !contentRef.current || !animationRef.current)
        return;

      const walk = 30; // px to move
      const { width, height } = heroRef.current.getBoundingClientRect();
      const { clientX, clientY } = e;

      const xWalk = (clientX / width) * walk - walk / 2;
      const yWalk = (clientY / height) * walk - walk / 2;

      contentRef.current.style.transform = `translate3d(${xWalk}px, ${yWalk}px, 0)`;
      animationRef.current.style.transform = `translate3d(${-xWalk}px, ${-yWalk}px, 0)`;
    };

    const heroElement = heroRef.current;
    if (heroElement) {
      heroElement.addEventListener("mousemove", handleParallax);
    }

    return () => {
      if (heroElement) {
        heroElement.removeEventListener("mousemove", handleParallax);
      }
    };
  }, []);

  useEffect(() => {
    setMounted(true);
    const handleScroll = () => {
      const scrolled = window.scrollY > 20;
      if (isScrolled !== scrolled) {
        setIsScrolled(scrolled);
      }
    };
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => window.removeEventListener("scroll", handleScroll);
  }, [isScrolled]);

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (isMenuOpen && !e.target.closest(`.${landingCss.mobileMenu}`)) {
        setIsMenuOpen(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => document.removeEventListener("click", handleClickOutside);
  }, [isMenuOpen]);

  useEffect(() => {
    let rafId;

    const handleMouseMove = (e) => {
      if (rafId) return;

      rafId = requestAnimationFrame(() => {
        const cards = document.querySelectorAll('[class*="Card"]');

        cards.forEach((card) => {
          const rect = card.getBoundingClientRect();
          const x = e.clientX - rect.left;
          const y = e.clientY - rect.top;

          card.style.setProperty("--mouse-x", `${x}px`);
          card.style.setProperty("--mouse-y", `${y}px`);
        });

        rafId = null;
      });
    };

    window.addEventListener("mousemove", handleMouseMove);
    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      if (rafId) {
        cancelAnimationFrame(rafId);
      }
    };
  }, []);

  if (!mounted) return null;

  return (
    <div className={landingCss.pageContainer}>
      <div className={landingCss.spotlightEffect} />
      <nav
        className={`${landingCss.navbar} ${
          isScrolled ? landingCss.scrolled : ""
        }`}
      >
        <div className={landingCss.navContent}>
          <Link to="/" className={landingCss.logo}>
            QuizBud
          </Link>

          {/* Desktop Menu */}
          <div className={landingCss.desktopMenu}>
            <button onClick={scrollToFeatures} className={landingCss.navLink}>
              Features
            </button>
            <Link to="/pricing" className={landingCss.navLink}>
              Pricing
            </Link>
            <Link to="/about" className={landingCss.navLink}>
              About
            </Link>
            <Link to="/contact" className={landingCss.navLink}>
              Contact
            </Link>
          </div>

          {/* Mobile Menu Button */}
          <button
            className={`${landingCss.menuButton} ${
              isMenuOpen ? landingCss.active : ""
            }`}
            onClick={(e) => {
              e.stopPropagation();
              setIsMenuOpen(!isMenuOpen);
            }}
          >
            <span className={landingCss.menuBar}></span>
            <span className={landingCss.menuBar}></span>
            <span className={landingCss.menuBar}></span>
          </button>

          {/* Mobile Menu */}
          <div
            className={`${landingCss.mobileMenu} ${
              isMenuOpen ? landingCss.open : ""
            }`}
          >
            <div className={landingCss.mobileMenuContent}>
              <button onClick={scrollToFeatures} className={landingCss.mobileNavLink}>
                Features
              </button>
              <Link to="/pricing" className={landingCss.mobileNavLink}>
                Pricing
              </Link>
              <Link to="/about" className={landingCss.mobileNavLink}>
                About
              </Link>
              <Link to="/contact" className={landingCss.mobileNavLink}>
                Contact
              </Link>
              <Link
                to="/auth"
                className={`${landingCss.primaryButton} ${landingCss.mobileCtaButton}`}
              >
                Get Started
                <FaArrowRight />
              </Link>
            </div>
          </div>

          {/* Desktop CTA */}
          <div className={landingCss.desktopCta}>
            <Link to="/auth" className={landingCss.primaryButton}>
              Get Started
              <FaArrowRight />
            </Link>
          </div>
        </div>
      </nav>

      <main className={landingCss.mainContent}>
        <div className={`${landingCss.heroSection} ${landingCss.section}`} ref={heroRef}>
          <div className={landingCss.heroContent} ref={contentRef}>
            <h1 className={landingCss.title}>
              <span className={landingCss.gradientText}>Study Smarter</span>
              <br />
              with AI-Powered Learning
            </h1>
            <p className={landingCss.subtitle}>
              Transform any content into effective study materials. From lecture
              recordings to handwritten notes, our AI makes learning effortless.
            </p>
            <div className={landingCss.heroButtons}>
              <button
                onClick={scrollToDemo}
                className={landingCss.primaryButton}
              >
                Meet Lumi
                <FaArrowRight />
              </button>
              <button
                onClick={scrollToFeatures}
                className={landingCss.secondaryButton}
              >
                Learn More
                <FaArrowRight />
              </button>
            </div>
          </div>
          <div className={landingCss.heroAnimation} ref={animationRef}>
            <Lottie
              animationData={globe}
              loop={true}
              style={{ width: 400, height: 400 }}
            />
          </div>
        </div>

        <div className={`${landingCss.featuresSection} ${landingCss.section}`} ref={featuresRef}>
          <div className={landingCss.sectionHeader}>
            <h2 className={landingCss.sectionTitle}>
              <span className={landingCss.gradientText}>
                AI-Powered Features
              </span>
            </h2>
            <p className={landingCss.sectionDescription}>
              Experience the future of learning with our advanced AI-driven
              study tools
            </p>
          </div>
          <div className={landingCss.featureGrid}>
            {features.map((feature) => (
              <div key={feature.title} className={landingCss.featureCard}>
                <div className={landingCss.glow} />
                <div className={landingCss.featureIcon}>{feature.icon}</div>
                <h3 className={landingCss.featureTitle}>{feature.title}</h3>
                <p className={landingCss.featureDescription}>
                  {feature.description}
                </p>
              </div>
            ))}
          </div>
        </div>

        <div className={`${landingCss.capabilitiesSection} ${landingCss.section}`}>
          <div className={landingCss.sectionHeader}>
            <h2 className={landingCss.sectionTitle}>
              <span className={landingCss.gradientText}>
                Powerful Capabilities
              </span>
            </h2>
            <p className={landingCss.sectionDescription}>
              Transform your study materials into interactive learning
              experiences
            </p>
          </div>
          <div className={landingCss.capabilitiesGrid}>
            {capabilities.map((capability) => (
              <div key={capability.title} className={landingCss.capabilityCard}>
                <div className={landingCss.glow} />
                <div className={landingCss.capabilityIcon}>
                  {capability.icon}
                </div>
                <h3 className={landingCss.capabilityTitle}>
                  {capability.title}
                </h3>
                <p className={landingCss.capabilityDescription}>
                  {capability.description}
                </p>
                <ul className={landingCss.capabilityFeatures}>
                  {capability.features.map((feature, index) => (
                    <li key={index} className={landingCss.capabilityFeature}>
                      {feature}
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>

        <div className={`${landingCss.demoSection} ${landingCss.section}`} ref={demoSectionRef}>
          <div className={landingCss.demoTitleSection}>
            <h2 className={landingCss.sectionTitle}>
              <span className={landingCss.gradientText}>
                Interactive AI Learning Assistant
              </span>
            </h2>
            <p className={landingCss.sectionDescription}>
              Meet Lumi, the AI-powered learning companion that transforms
              complex topics into clear, engaging explanations. Ask questions,
              explore concepts, and master any subject with personalized
              guidance.
            </p>
          </div>
          <div className={landingCss.demoContainer}>
            <ModernDemo />
          </div>
        </div>

        <div className={`${landingCss.statsSection} ${landingCss.section}`}>
          <div className={landingCss.sectionContent}>
            <h2 className={landingCss.sectionTitle}>
              <span className={landingCss.gradientText}>
                Empowering Student Success
              </span>
            </h2>
            <p className={landingCss.sectionDescription}>
              Discover how QuizBud is helping students achieve their academic
              goals
            </p>
            <div className={landingCss.statsGrid}>
              {stats.map((stat) => (
                <div key={stat.label} className={landingCss.statCard}>
                  <div className={landingCss.statValue}>{stat.value}</div>
                  <div className={landingCss.statLabel}>{stat.label}</div>
                  <div className={landingCss.statDescription}>
                    {stat.description}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className={`${landingCss.testimonialSection} ${landingCss.section}`}>
          <div className={landingCss.sectionHeader}>
            <h2 className={landingCss.sectionTitle}>
              <span className={landingCss.gradientText}>What Students Say</span>
            </h2>
            <p className={landingCss.sectionDescription}>
              Discover how QuizBud is helping students achieve their academic
              goals
            </p>
          </div>
          <div className={landingCss.testimonialGrid}>
            {testimonials.map((testimonial) => (
              <div
                key={testimonial.author}
                className={landingCss.testimonialCard}
              >
                <div className={landingCss.glow} />
                <div className={landingCss.testimonialContent}>
                  <p className={landingCss.testimonialQuote}>
                    "{testimonial.quote}"
                  </p>
                  <div className={landingCss.testimonialAuthor}>
                    <div className={landingCss.testimonialAvatar}>
                      {testimonial.initial}
                    </div>
                    <div>
                      <div className={landingCss.testimonialName}>
                        {testimonial.author}
                      </div>
                      <div className={landingCss.testimonialTitle}>
                        {testimonial.title}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        <section className={`${landingCss.faqSection} ${landingCss.section}`}>
          <div className={landingCss.sectionHeader}>
            <h2 className={landingCss.sectionTitle}>
              <span className={landingCss.gradientText}>
                Frequently Asked Questions
              </span>
            </h2>
            <p className={landingCss.sectionDescription}>
              Find answers to common questions about QuizBud's features and services
            </p>
          </div>
          <div className={landingCss.faqGrid}>
            <div className={landingCss.faqCard}>
              <div className={landingCss.faqQuestion}>
                <FaChevronRight className={landingCss.faqIcon} />
                How does QuizBud generate questions?
              </div>
              <div className={landingCss.faqAnswer}>
                Our AI analyzes your study materials to create relevant, challenging questions that are tailored to your learning goals.
              </div>
            </div>

            <div className={landingCss.faqCard}>
              <div className={landingCss.faqQuestion}>
                <FaChevronRight className={landingCss.faqIcon} />
                Can I upgrade or downgrade my plan?
              </div>
              <div className={landingCss.faqAnswer}>
                Yes! You can change your plan anytime. Upgrades are instant, and downgrades take effect at the end of your billing period.
              </div>
            </div>

            <div className={landingCss.faqCard}>
              <div className={landingCss.faqQuestion}>
                <FaChevronRight className={landingCss.faqIcon} />
                Is there a limit to study materials?
              </div>
              <div className={landingCss.faqAnswer}>
                Basic plans include 25 uploads monthly. Pro plans offer unlimited uploads with support for text, audio, and images.
              </div>
            </div>

            <div className={landingCss.faqCard}>
              <div className={landingCss.faqQuestion}>
                <FaChevronRight className={landingCss.faqIcon} />
                How accurate are the AI-generated quizzes?
              </div>
              <div className={landingCss.faqAnswer}>
                Our AI maintains high accuracy through advanced NLP, with continuous improvements and user feedback integration.
              </div>
            </div>

            <div className={landingCss.faqCard}>
              <div className={landingCss.faqQuestion}>
                <FaChevronRight className={landingCss.faqIcon} />
                What payment methods do you accept?
              </div>
              <div className={landingCss.faqAnswer}>
                We accept all major credit cards, PayPal, and Apple Pay, with secure processing through Stripe.
              </div>
            </div>

            <div className={landingCss.faqCard}>
              <div className={landingCss.faqQuestion}>
                <FaChevronRight className={landingCss.faqIcon} />
                Is there a free trial?
              </div>
              <div className={landingCss.faqAnswer}>
                Yes! Try QuizBud free for 7 days with full Pro features. No credit card required.
              </div>
            </div>
          </div>
        </section>

        <div className={`${landingCss.ctaSection} ${landingCss.section}`}>
          <div className={landingCss.ctaContent}>
            <h2 className={landingCss.ctaHeading}>
              Ready to Transform Your Study Experience?
            </h2>
            <p className={landingCss.ctaDescription}>
              Join thousands of students who are already studying smarter, not harder.
              Start your journey with QuizBud today and experience the future of learning.
            </p>
            <div className={landingCss.ctaButtons}>
              <Link to="/auth" className={landingCss.primaryButton}>
                Get Started
                <FaArrowRight />
              </Link>
              <Link to="/pricing" className={landingCss.secondaryButton}>
                View Pricing
                <FaArrowRight />
              </Link>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default LandingPage;
