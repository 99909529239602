import React, { useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import styles from '../styles/about.module.css';
import { ArrowLeft, ArrowRight, TrendingUp, Users, Globe, Book, Star, Clock, Activity, Award } from 'react-feather';

// Memoized data to optimize performance
const CORE_PRINCIPLES = [
  {
    number: '01',
    title: 'Adaptive Learning',
    description: 'Our AI engine continuously analyzes learning patterns and adapts in real-time, creating a truly personalized educational journey.',
    metric: 'Explore AI Technology'
  },
  {
    number: '02',
    title: 'Scientific Approach',
    description: 'Built on cognitive science principles, our platform optimizes information retention and understanding through spaced repetition.',
    metric: 'View Research'
  },
  {
    number: '03',
    title: 'Continuous Evolution',
    description: 'We leverage machine learning to continuously improve our platform, ensuring learning strategies stay effective and engaging.',
    metric: 'See Our Process'
  }
];

const IMPACT_METRICS = [
  {
    metric: '500K+',
    label: 'Active Learners',
    description: 'Students experiencing personalized learning paths tailored to their unique cognitive patterns.',
    icon: <Users size={24} />
  },
  {
    metric: '85%',
    label: 'Knowledge Retention',
    description: 'Average improvement in long-term retention through our AI-powered spaced repetition system.',
    icon: <Book size={24} />
  },
  {
    metric: '40+',
    label: 'Global Reach',
    description: 'Countries where our platform is revolutionizing the way students learn and retain information.',
    icon: <Globe size={24} />
  },
  {
    metric: '47%',
    label: 'Learning Efficiency',
    description: 'Average reduction in study time while achieving better comprehension and retention rates.',
    icon: <TrendingUp size={24} />
  },
  {
    metric: '98%',
    label: 'Student Satisfaction',
    description: 'Students report higher engagement and satisfaction with their learning experience.',
    icon: <Star size={24} />
  },
  {
    metric: '24/7',
    label: 'AI Support',
    description: 'Round-the-clock intelligent tutoring and assistance available to all students.',
    icon: <Clock size={24} />
  },
  {
    metric: '15M+',
    label: 'Learning Sessions',
    description: 'Successful learning sessions completed through our adaptive platform.',
    icon: <Activity size={24} />
  },
  {
    metric: '92%',
    label: 'Course Completion',
    description: 'Higher course completion rates compared to traditional learning methods.',
    icon: <Award size={24} />
  }
];

const About = () => {
  const handleIntersection = useCallback((entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        entry.target.classList.add(styles.inView);
        entry.target.observer?.unobserve(entry.target);
      }
    });
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(handleIntersection, {
      threshold: 0.1,
      rootMargin: '50px',
    });

    const elements = document.querySelectorAll('[data-animate]');
    elements.forEach((el) => observer.observe(el));

    return () => observer.disconnect();
  }, [handleIntersection]);

  return (
    <div className={styles.aboutContainer}>
      <div className={styles.content}>
        <Link to="/" className={styles.backButton}>
          <ArrowLeft size={18} />
          Back
        </Link>

        <header className={styles.header}>
          <div className={styles.titleWrapper}>
            <h1 className={styles.title}>
              Redefining Digital Learning
            </h1>
          </div>
          <p className={styles.subtitle}>
            Leveraging advanced AI technology to create personalized learning experiences
            that adapt to individual needs and learning styles.
          </p>
        </header>

        <section className={styles.section}>
          <h2 className={styles.sectionTitle} data-animate>
            Core Principles
          </h2>
          
          <div className={styles.valueGrid}>
            {CORE_PRINCIPLES.map((value, index) => (
              <div 
                key={value.number}
                className={styles.valueCard}
                data-animate
                style={{ animationDelay: `${0.1 * (index + 2)}s` }}
              >
                <span className={styles.valueNumber}>{value.number}</span>
                <h3 className={styles.valueTitle}>{value.title}</h3>
                <p className={styles.valueDescription}>{value.description}</p>
                <div className={styles.valueMetric}>
                  {value.metric} <ArrowRight size={16} />
                </div>
              </div>
            ))}
          </div>
        </section>

        <section className={`${styles.section} ${styles.impactSection}`}>
          <h2 className={styles.sectionTitle} data-animate>
            Impact at Scale
          </h2>
          
          <div className={styles.impactCarouselContainer}>
            <div className={styles.impactCarousel}>
              {[...IMPACT_METRICS, ...IMPACT_METRICS, ...IMPACT_METRICS].map((impact, index) => (
                <div 
                  key={`${impact.label}-${index}`}
                  className={styles.impactCard}
                >
                  <div className={styles.impactIcon}>{impact.icon}</div>
                  <div className={styles.impactMetric}>{impact.metric}</div>
                  <h3 className={styles.impactLabel}>{impact.label}</h3>
                  <p className={styles.impactDescription}>{impact.description}</p>
                </div>
              ))}
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default About;
