import React, { memo } from 'react';
import { FiUpload } from 'react-icons/fi';
import styles from '../styles/home.module.css';

const ContentInput = memo(({ onTextChange, onFileChange }) => {
  return (
    <div className={styles.inputContainer}>
      <div className={styles.textInput}>
        <textarea
          className={styles.textarea}
          placeholder="Enter or paste your study content here..."
          onChange={onTextChange}
          rows={4}
        />
      </div>
      
      <div className={styles.fileUpload}>
        <input
          type="file"
          id="file-upload"
          className={styles.fileInput}
          accept=".pdf,.docx,.txt,.md"
          onChange={onFileChange}
        />
        <label htmlFor="file-upload" className={styles.fileLabel}>
          <FiUpload size={20} />
          <span>Drop files or click</span>
        </label>
      </div>
    </div>
  );
});

ContentInput.displayName = 'ContentInput';

export default ContentInput;
