import React, { useState, useCallback, useMemo } from "react";
import { Link } from "react-router-dom";
import {
  FiUpload,
  FiMic,
  FiEdit,
  FiBook,
  FiClock,
  FiAward,
  FiSun,
  FiMoon,
  FiSettings,
  FiTrendingUp,
  FiPieChart,
  FiActivity,
  FiBell,
  FiSearch,
  FiLock,
  FiZap,
  FiTarget,
  FiBarChart2,
  FiGitBranch,
  FiFolder
} from "react-icons/fi";
import { useTheme } from "../context/ThemeContext";
import { useAuth } from "../context/AuthContext";
import styles from "../styles/home.module.css";
import Modal from './Modal';
import ContentInput from './ContentInput';
import ThemeToggle from './ThemeToggle';
import '../styles/theme.css';
import QuizGenerator from './QuizGenerator'; // Import QuizGenerator component

const features = [
  {
    id: "content-input",
    icon: <FiEdit />,
    title: "Content Input",
    description: "Input or upload your study content",
    metrics: { processed: "10K+", formats: "Multiple" },
    isPremium: false
  },
  {
    id: "quiz-generation",
    icon: <FiBook />,
    title: "Quiz Generation",
    description: "Generate practice quizzes from your content",
    metrics: { generated: "50K+", accuracy: "95%" },
    isPremium: false
  },
  {
    id: "flashcard-generation",
    icon: <FiClock />,
    title: "Flashcard Generation",
    description: "Create study flashcards automatically",
    metrics: { sets: "25K+", retention: "92%" },
    isPremium: false
  },
  {
    id: "summary-generation",
    icon: <FiTrendingUp />,
    title: "Summary Generation",
    description: "Get concise summaries of your content",
    metrics: { summaries: "30K+", accuracy: "98%" },
    isPremium: false
  },
  {
    id: "voice-notes",
    icon: <FiMic />,
    title: "Voice Notes",
    description: "Convert lectures into structured content",
    metrics: { hours: "150+", languages: "12" },
    isPremium: true
  },
  {
    id: "advanced-analytics",
    icon: <FiActivity />,
    title: "Advanced Analytics",
    description: "In-depth learning analytics and insights",
    metrics: { tracked: "100K+", improvement: "45%" },
    isPremium: true
  },
  {
    id: "ai-tutor",
    icon: <FiTarget />,
    title: "AI Tutor",
    description: "Personalized AI-powered tutoring sessions",
    metrics: { sessions: "50K+", satisfaction: "96%" },
    isPremium: true
  },
  {
    id: "concept-mapping",
    icon: <FiGitBranch />,
    title: "Concept Mapping",
    description: "Visual learning path generation",
    metrics: { maps: "20K+", connections: "1M+" },
    isPremium: true
  },
  {
    id: "performance-prediction",
    icon: <FiBarChart2 />,
    title: "Performance Prediction",
    description: "AI-driven study success forecasting",
    metrics: { accuracy: "94%", insights: "30K+" },
    isPremium: true
  },
  {
    id: "study-optimization",
    icon: <FiZap />,
    title: "Study Optimization",
    description: "Personalized learning schedule optimization",
    metrics: { efficiency: "+40%", plans: "25K+" },
    isPremium: true
  }
];

const recentActivity = [
  {
    id: 1,
    icon: <FiTrendingUp />,
    title: "Performance Analysis Updated",
    time: "2 hours ago",
    detail: "Physics Mechanics: 92% comprehension rate"
  },
  {
    id: 2,
    icon: <FiPieChart />,
    title: "Study Pattern Analysis",
    time: "5 hours ago",
    detail: "Optimal study time identified: 9:00 AM - 11:00 AM"
  },
  {
    id: 3,
    icon: <FiActivity />,
    title: "Learning Progress Report",
    time: "Yesterday",
    detail: "Weekly improvement: +15% in Biology"
  },
];

const HomePage = () => {
  const { isDarkMode, toggleTheme } = useTheme();
  const { user } = useAuth();
  const [activeModal, setActiveModal] = useState(null);
  const [isUserPremium] = useState(false);

  const handleFeatureClick = useCallback((feature) => {
    setActiveModal(feature.id);
  }, []);

  const closeModal = useCallback(() => {
    setActiveModal(null);
  }, []);

  const handleTextChange = useCallback((e) => {
    console.log('Text changed:', e.target.value);
  }, []);

  const handleFileChange = useCallback((e) => {
    console.log('File selected:', e.target.files[0]);
  }, []);

  const renderModalContent = useCallback((featureId) => {
    if (featureId === 'quiz-generation') {
      return <QuizGenerator onGenerate={(settings) => {
        console.log('Generating quiz with settings:', settings);
        // Add quiz generation logic here
        closeModal();
      }} />;
    }

    const modalOptions = {
      'flashcard-generation': {
        title: 'Flashcard Settings',
        items: [
          { type: 'select', label: 'Cards per concept', options: ['1', '2', '3'] },
          { type: 'checkbox', label: 'Include Examples', defaultChecked: true }
        ]
      },
      'summary-generation': {
        title: 'Summary Options',
        items: [
          { type: 'select', label: 'Length', options: ['Brief', 'Detailed', 'Comprehensive'] },
          { type: 'checkbox', label: 'Include Key Points', defaultChecked: true }
        ]
      }
    };

    const renderOptions = (options) => (
      <div className={styles.formGroup}>
        <label className={styles.label}>{options.title}</label>
        <div className={styles.optionsGrid}>
          {options.items.map((item) => (
            <div key={item.label} className={styles.optionItem}>
              {item.type === 'select' ? (
                <>
                  <label>{item.label}:</label>
                  <select className={styles.select}>
                    {item.options.map((opt) => (
                      <option key={opt} value={opt}>{opt}</option>
                    ))}
                  </select>
                </>
              ) : (
                <label>
                  <input
                    type="checkbox"
                    defaultChecked={item.defaultChecked}
                  />
                  <span>{item.label}</span>
                </label>
              )}
            </div>
          ))}
        </div>
      </div>
    );

    return (
      <div className={styles.modalForm}>
        <ContentInput
          onTextChange={handleTextChange}
          onFileChange={handleFileChange}
        />
        {modalOptions[featureId] && renderOptions(modalOptions[featureId])}
        <div className={styles.buttonGroup}>
          <button className={`${styles.button} ${styles.primaryButton}`}>
            {featureId.split('-').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}
          </button>
        </div>
      </div>
    );
  }, [handleTextChange, handleFileChange, closeModal]);

  const sortedFeatures = useMemo(() => {
    return [...features].sort((a, b) => {
      if (a.isPremium === b.isPremium) return 0;
      return a.isPremium ? 1 : -1;
    });
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.spotlightEffect} />
      
      <header className={styles.header}>
        <div className={styles.headerContent}>
          <Link to="/home" className={styles.logo}>
            QuizMate
          </Link>
          
          <div className={styles.searchContainer}>
            <FiSearch className={styles.searchIcon} />
            <input
              type="text"
              placeholder="Search study materials, notes, or topics..."
              className={styles.searchInput}
            />
          </div>

          <div className={styles.userControls}>
            <Link to="/resources" className={styles.iconButton} aria-label="Resources">
              <FiFolder size={20} />
            </Link>
            <button className={styles.iconButton} aria-label="Notifications">
              <FiBell size={20} />
              <span className={styles.notificationBadge}>2</span>
            </button>
            <ThemeToggle />
          </div>
        </div>
      </header>

      <main className={styles.mainContent}>
        <div className={styles.pageHeader}>
          <div>
            <h1 className={styles.welcomeTitle}>
              Welcome back, {user?.name || "Learner"}
            </h1>
            <p className={styles.welcomeSubtitle}>
              Your learning analytics and study materials are ready for review
            </p>
          </div>
          <div className={styles.quickStats}>
            <div className={styles.statItem}>
              <span className={styles.statValue}>92%</span>
              <span className={styles.statLabel}>Weekly Progress</span>
            </div>
            <div className={styles.statItem}>
              <span className={styles.statValue}>15</span>
              <span className={styles.statLabel}>Study Hours</span>
            </div>
            <div className={styles.statItem}>
              <span className={styles.statValue}>8</span>
              <span className={styles.statLabel}>Topics Mastered</span>
            </div>
          </div>
        </div>

        <div className={styles.contentGrid}>
          <div className={styles.mainSection}>
            <div className={styles.sectionHeader}>
              <h2 className={styles.sectionTitle}>Learning Tools</h2>
              <p className={styles.sectionSubtitle}>
                Access your AI-powered study enhancement tools
              </p>
            </div>
            
            <div className={styles.featuresGrid}>
              {sortedFeatures.map((feature) => (
                <div
                  key={feature.id}
                  className={`${styles.featureCard} ${feature.isPremium ? styles.premiumFeature : ''}`}
                  onClick={() => handleFeatureClick(feature)}
                  role="button"
                  tabIndex={0}
                >
                  {feature.isPremium && (
                    <div className={styles.premiumBadge}>
                      <FiLock /> Premium
                    </div>
                  )}
                  <div className={styles.featureIcon}>{feature.icon}</div>
                  <div className={styles.featureContent}>
                    <h3 className={styles.featureTitle}>{feature.title}</h3>
                    <p className={styles.featureDescription}>{feature.description}</p>
                  </div>
                  <div className={styles.featureMetrics}>
                    {Object.entries(feature.metrics).map(([key, value]) => (
                      <div key={key} className={styles.metricItem}>
                        <span className={styles.metricValue}>{value}</span>
                        <span className={styles.metricLabel}>{key}</span>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>

          <aside className={styles.sidebar}>
            <div className={styles.sectionHeader}>
              <h2 className={styles.sectionTitle}>Recent Activity</h2>
            </div>
            <div className={styles.activityList}>
              {recentActivity.map((activity) => (
                <div key={activity.id} className={styles.activityItem}>
                  <div className={styles.activityIcon}>{activity.icon}</div>
                  <div className={styles.activityContent}>
                    <h4 className={styles.activityTitle}>{activity.title}</h4>
                    <p className={styles.activityDetail}>{activity.detail}</p>
                    <span className={styles.activityTime}>{activity.time}</span>
                  </div>
                </div>
              ))}
            </div>
          </aside>
        </div>
      </main>

      {features.map((feature) => (
        <Modal
          key={feature.id}
          isOpen={activeModal === feature.id}
          onClose={closeModal}
          title={feature.title}
          isPremiumFeature={feature.isPremium}
          isUserPremium={isUserPremium}
        >
          {renderModalContent(feature.id)}
        </Modal>
      ))}
    </div>
  );
};

export default React.memo(HomePage);
