import React, { useState, useEffect, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { 
  MoreVertical, Upload, AlertCircle, Book, Layout, FileText,
  Edit2, Download, Share2, Zap, Layers, Trash2,
  Search, Filter, Grid as GridIcon, List as ListIcon
} from 'react-feather';
import DocumentPreview from '../components/DocumentPreview';
import SidePanel from '../components/SidePanel';
import styles from '../styles/Resources.module.css';
import { 
  saveFileToStorage, 
  getFilesFromStorage, 
  deleteFileFromStorage, 
  base64ToFile,
  formatFileSize 
} from '../utils/fileStorage';
import { useNavigate } from 'react-router-dom';
import { FiArrowLeft } from 'react-icons/fi';
import { useSidePanel } from '../context/SidePanelContext';

const RESOURCE_TYPES = [
  {
    number: '01',
    title: 'Study Materials',
    description: 'Upload and manage your study materials, notes, and documents.',
    icon: <FileText size={24} />,
    metric: '25+ Formats Supported'
  },
  {
    number: '02',
    title: 'Generated Quizzes',
    description: 'Access AI-generated quizzes created from your study materials.',
    icon: <Book size={24} />,
    metric: 'Smart Generation'
  },
  {
    number: '03',
    title: 'Flashcard Sets',
    description: 'Practice with interactive flashcards for better retention.',
    icon: <Layout size={24} />,
    metric: 'Spaced Repetition'
  }
];

const Resources = () => {
  const navigate = useNavigate();
  const { openPanel } = useSidePanel();

  const [viewMode, setViewMode] = useState('grid');
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedType, setSelectedType] = useState('all');
  const [showFilters, setShowFilters] = useState(false);
  const [resources, setResources] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [sidePanelFile, setSidePanelFile] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Load resources from local storage on mount
  useEffect(() => {
    try {
      const storedFiles = getFilesFromStorage();
      setResources(storedFiles);
    } catch (error) {
      setError('Failed to load resources');
      console.error('Error loading resources:', error);
    } finally {
      setLoading(false);
    }
  }, []);

  const onDrop = useCallback(async (acceptedFiles) => {
    try {
      setLoading(true);
      const file = acceptedFiles[0];
      
      // Save file to local storage
      const savedFile = await saveFileToStorage(file);
      
      // Update resources state
      setResources(prev => [...prev, savedFile]);
      setError(null);
    } catch (error) {
      setError('Failed to upload file');
      console.error('Error uploading file:', error);
    } finally {
      setLoading(false);
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'application/pdf': ['.pdf'],
      'application/msword': ['.doc'],
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
      'text/plain': ['.txt']
    },
    maxSize: 10485760, // 10MB
    multiple: false
  });

  const handleAction = (action, resource) => {
    switch (action) {
      case 'delete':
        try {
          deleteFileFromStorage(resource.id);
          setResources(prev => prev.filter(r => r.id !== resource.id));
        } catch (error) {
          setError('Failed to delete resource');
          console.error('Error deleting resource:', error);
        }
        break;
      case 'rename':
        try {
          const updatedResources = resources.map(r => 
            r.id === resource.id ? { ...r, name: resource.name } : r
          );
          localStorage.setItem('quizmate_files', JSON.stringify(updatedResources));
          setResources(updatedResources);
        } catch (error) {
          setError('Failed to rename resource');
          console.error('Error renaming resource:', error);
        }
        break;
      case 'openSide':
        const file = base64ToFile(resource.content, resource.name, resource.type);
        openPanel(file);
        break;
      default:
        console.log(`Action ${action} not implemented`);
    }
  };

  const handlePreview = (resource) => {
    // Convert base64 back to File object for preview
    const file = base64ToFile(resource.content, resource.name, resource.type);
    setSelectedFile(file);
  };

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleTypeChange = (type) => {
    setSelectedType(type);
  };

  const handleViewModeChange = (mode) => {
    setViewMode(mode);
  };

  const handleUpload = () => {
    console.log('Upload clicked');
  };

  const handleCreateNew = () => {
    console.log('Create new clicked');
  };

  return (
    <div className={styles.resourcesContainer}>
      <button 
        className={styles.backButton}
        onClick={() => navigate('/home')}
      >
        <FiArrowLeft size={20} />
        <span>Back to Home</span>
      </button>
      <div className={styles.content}>
        <header className={styles.header}>
          <div className={styles.titleWrapper}>
            <h1 className={styles.title}>
              My Resources
            </h1>
            <p className={styles.subtitle}>
              Manage all your study materials, quizzes, and flashcards in one place.
              Upload content and let our AI help you learn more effectively.
            </p>
          </div>
        </header>

        <section className={styles.resourceTypes}>
          <h2 className={styles.sectionTitle}>
            Resource Types
          </h2>
          <div className={styles.typeGrid}>
            {RESOURCE_TYPES.map((type, index) => (
              <div
                key={type.title}
                className={styles.typeCard}
                style={{ animationDelay: `${0.1 * (index + 2)}s` }}
              >
                <div className={styles.typeNumber}>{type.number}</div>
                <div className={styles.typeIcon}>{type.icon}</div>
                <h3 className={styles.typeTitle}>{type.title}</h3>
                <p className={styles.typeDescription}>{type.description}</p>
                <span className={styles.typeMetric}>{type.metric}</span>
              </div>
            ))}
          </div>
        </section>

        <section className={styles.resourceSection}>
          <div className={styles.sectionHeader}>
            <h2 className={styles.sectionTitle}>
              My Content
            </h2>
          </div>

          <div {...getRootProps()} className={`${styles.uploadZone} ${isDragActive ? styles.dragOver : ''}`}>
            <input {...getInputProps()} />
            <div className={styles.uploadIcon}>
              <Upload size={48} />
            </div>
            <p className={styles.uploadText}>
              {isDragActive ? 'Drop your files here' : 'Drag and drop your files here'}
            </p>
            <p className={styles.uploadSubtext}>
              or click to select files (PDF, DOC, DOCX, TXT)
            </p>
          </div>

          {error && (
            <div className={styles.error}>
              <AlertCircle size={20} />
              <p>{error}</p>
            </div>
          )}

          <div className={styles.resourceControls}>
            <div className={styles.searchBar}>
              <Search size={18} />
              <input
                type="text"
                placeholder="Search resources..."
                value={searchQuery}
                onChange={handleSearch}
              />
              <button 
                className={styles.filterButton}
                onClick={() => setShowFilters(!showFilters)}
              >
                <Filter size={18} />
              </button>
            </div>
            <div className={styles.viewControls}>
              <button 
                className={`${styles.viewButton} ${viewMode === 'grid' ? styles.active : ''}`}
                onClick={() => handleViewModeChange('grid')}
              >
                <GridIcon size={18} />
              </button>
              <button 
                className={`${styles.viewButton} ${viewMode === 'list' ? styles.active : ''}`}
                onClick={() => handleViewModeChange('list')}
              >
                <ListIcon size={18} />
              </button>
            </div>
          </div>

          {showFilters && (
            <div className={styles.filters}>
              <button
                className={`${styles.filterChip} ${selectedType === 'all' ? styles.active : ''}`}
                onClick={() => handleTypeChange('all')}
              >
                All
              </button>
              <button
                className={`${styles.filterChip} ${selectedType === 'documents' ? styles.active : ''}`}
                onClick={() => handleTypeChange('documents')}
              >
                Documents
              </button>
              <button
                className={`${styles.filterChip} ${selectedType === 'quizzes' ? styles.active : ''}`}
                onClick={() => handleTypeChange('quizzes')}
              >
                Quizzes
              </button>
              <button
                className={`${styles.filterChip} ${selectedType === 'flashcards' ? styles.active : ''}`}
                onClick={() => handleTypeChange('flashcards')}
              >
                Flashcards
              </button>
            </div>
          )}

          <div className={viewMode === 'grid' ? styles.resourcesGrid : styles.resourcesList}>
            {resources.map(resource => (
              viewMode === 'grid' ? (
                <ResourceCard key={resource.id} resource={resource} onAction={handleAction} onPreview={handlePreview} />
              ) : (
                <ResourceList key={resource.id} resource={resource} onAction={handleAction} />
              )
            ))}
          </div>
        </section>
      </div>
      {selectedFile && (
        <DocumentPreview
          file={selectedFile}
          onClose={() => setSelectedFile(null)}
        />
      )}
      {sidePanelFile && (
        <SidePanel
          file={sidePanelFile}
          onClose={() => setSidePanelFile(null)}
        />
      )}
    </div>
  );
};

const ResourceCard = ({ resource, onAction, onPreview }) => {
  const [showActions, setShowActions] = useState(false);
  const [isRenaming, setIsRenaming] = useState(false);
  const [newName, setNewName] = useState(resource.name);

  const handleActionClick = (action) => {
    setShowActions(false);
    onAction(action, resource);
  };

  const handleRename = (e) => {
    e.stopPropagation();
    if (isRenaming && newName.trim()) {
      onAction('rename', { ...resource, name: newName.trim() });
      setIsRenaming(false);
    } else {
      setIsRenaming(true);
    }
  };

  const handleRenameKeyDown = (e) => {
    if (e.key === 'Enter' && newName.trim()) {
      e.preventDefault();
      onAction('rename', { ...resource, name: newName.trim() });
      setIsRenaming(false);
    } else if (e.key === 'Escape') {
      setIsRenaming(false);
      setNewName(resource.name);
    }
  };

  const handleRenameBlur = () => {
    if (newName.trim()) {
      onAction('rename', { ...resource, name: newName.trim() });
    } else {
      setNewName(resource.name);
    }
    setIsRenaming(false);
  };

  const getFileTypeTag = (type) => {
    switch (type) {
      case 'application/pdf':
        return 'PDF';
      case 'text/plain':
        return 'TXT';
      default:
        return type.split('/')[1].toUpperCase();
    }
  };

  return (
    <div className={styles.resourceCard}>
      <div 
        className={styles.resourceContent}
        onClick={() => !isRenaming && onPreview(resource)}
        style={{ cursor: isRenaming ? 'default' : 'pointer' }}
      >
        {isRenaming ? (
          <div className={styles.renameWrapper} onClick={e => e.stopPropagation()}>
            <input
              type="text"
              value={newName}
              onChange={(e) => setNewName(e.target.value)}
              onKeyDown={handleRenameKeyDown}
              onBlur={handleRenameBlur}
              autoFocus
              className={styles.renameInput}
            />
          </div>
        ) : (
          <h3>{resource.name}</h3>
        )}
        <div className={styles.resourceMeta}>
          <span>{getFileTypeTag(resource.type)}</span>
          <span>{formatFileSize(resource.size)}</span>
          <span>Added: {new Date(resource.createdAt).toLocaleDateString()}</span>
        </div>
      </div>
      <div className={styles.resourceActions}>
        <button onClick={() => handleActionClick('openSide')}>
          <Layout size={18} />
        </button>
        <button onClick={handleRename}>
          <Edit2 size={18} />
        </button>
        <button onClick={() => setShowActions(!showActions)}>
          <MoreVertical size={18} />
        </button>
        {showActions && (
          <div className={`${styles.resourceActionMenu} ${showActions ? styles.show : ''}`}>
            <div className={styles.resourceActionItem} onClick={() => handleActionClick('delete')}>
              <Trash2 size={16} />
              Delete
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const ResourceList = ({ resource, onAction }) => {
  const [showActions, setShowActions] = useState(false);

  const handleActionClick = (action) => {
    setShowActions(false);
    onAction(action, resource);
  };

  const getFileTypeTag = (type) => {
    switch (type) {
      case 'application/pdf':
        return 'PDF';
      case 'text/plain':
        return 'TXT';
      default:
        return type.split('/')[1].toUpperCase();
    }
  };

  const getFileIcon = (type) => {
    switch (type) {
      case 'application/pdf':
        return <FileText size={24} />;
      case 'text/plain':
        return <FileText size={24} />;
      default:
        return <FileText size={24} />;
    }
  };

  return (
    <div className={styles.resourceListItem}>
      <div className={styles.resourceInfo}>
        <div className={styles.resourceIcon}>
          {getFileIcon(resource.type)}
        </div>
        <div className={styles.resourceDetails}>
          <h3>{resource.name}</h3>
          <div className={styles.resourceMeta}>
            <span>{getFileTypeTag(resource.type)}</span>
            <span>{formatFileSize(resource.size)}</span>
            <span>Added: {new Date(resource.createdAt).toLocaleDateString()}</span>
          </div>
        </div>
      </div>
      <div className={styles.resourceActions}>
        <button onClick={() => handleActionClick('quiz')}>
          <Book size={18} />
        </button>
        <button onClick={() => handleActionClick('flashcards')}>
          <Layout size={18} />
        </button>
        <button onClick={() => handleActionClick('summary')}>
          <FileText size={18} />
        </button>
        <button onClick={() => setShowActions(!showActions)}>
          <MoreVertical size={18} />
        </button>
        {showActions && (
          <div className={`${styles.resourceActionMenu} ${showActions ? styles.show : ''}`}>
            <div className={styles.resourceActionItem} onClick={() => handleActionClick('edit')}>
              <Edit2 size={16} />
              Edit
            </div>
            <div className={styles.resourceActionItem} onClick={() => handleActionClick('download')}>
              <Download size={16} />
              Download
            </div>
            <div className={styles.resourceActionItem} onClick={() => handleActionClick('share')}>
              <Share2 size={16} />
              Share
            </div>
            <div className={`${styles.resourceActionItem} ${styles.premium}`} onClick={() => handleActionClick('advanced')}>
              <Zap size={16} />
              Advanced Analysis
            </div>
            <div className={`${styles.resourceActionItem} ${styles.premium}`} onClick={() => handleActionClick('custom')}>
              <Layers size={16} />
              Custom Quiz
            </div>
            <div className={styles.resourceActionItem} onClick={() => handleActionClick('delete')}>
              <Trash2 size={16} />
              Delete
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Resources;
