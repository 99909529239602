import React, { useState, useEffect } from 'react';
import { X, AlertCircle } from 'react-feather';
import styles from '../styles/documentPreview.module.css';

const DocumentPreview = ({ file, onClose }) => {
  const [error, setError] = useState(null);
  const [textContent, setTextContent] = useState(null);
  const [objectUrl, setObjectUrl] = useState(null);

  useEffect(() => {
    if (file) {
      // Create object URL for PDF files
      if (file.type === 'application/pdf') {
        const url = URL.createObjectURL(file);
        setObjectUrl(url);
        return () => URL.revokeObjectURL(url);
      }
      
      // Read text files
      if (file.type.startsWith('text/')) {
        const reader = new FileReader();
        reader.onload = (e) => {
          setTextContent(e.target.result);
        };
        reader.onerror = () => {
          setError('Error reading file. Please try again.');
        };
        reader.readAsText(file);
      }
    }
  }, [file]);

  const renderContent = () => {
    if (!file) return null;

    if (error) {
      return (
        <div className={styles.errorContainer}>
          <AlertCircle size={48} />
          <p>{error}</p>
        </div>
      );
    }

    if (file.type === 'application/pdf' && objectUrl) {
      return (
        <iframe
          src={objectUrl}
          className={styles.pdfViewer}
          title="PDF viewer"
        />
      );
    }

    if (file.type.startsWith('text/') && textContent !== null) {
      return (
        <div className={styles.textPreview}>
          <pre>{textContent}</pre>
        </div>
      );
    }

    return (
      <div className={styles.unsupported}>
        <AlertCircle size={48} />
        <p>Preview not available for this file type.</p>
        <p>Supported formats: PDF, TXT</p>
      </div>
    );
  };

  return (
    <div className={styles.previewOverlay} onClick={(e) => {
      if (e.target === e.currentTarget) onClose();
    }}>
      <div className={styles.previewContent}>
        <div className={styles.previewHeader}>
          <h3>{file?.name}</h3>
          <button className={styles.closeButton} onClick={onClose}>
            <X size={20} />
          </button>
        </div>
        
        <div className={styles.previewBody}>
          {renderContent()}
        </div>
      </div>
    </div>
  );
};

export default DocumentPreview;
